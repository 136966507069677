<header>
    <div>
        <a href="#eyecatcher">
            <p class="logo center-vertically">
                Thinking Outside The Box
            </p>    
        </a>
    </div>
    
    <app-menu></app-menu>
</header>
