<div id="menu" class="fit-header">
    <div>
        <a href="#wie">wie</a>
    </div>
    <div>
        <a href="#wat">wat</a>
    </div>
    <div>
        <a href="#normen-en-waarden">waarden</a>
    </div>
    <div>
        <a href="mailto:meedenken@thinkingoutsidethebox.nl?subject=Kan%20je%20even%20meedenken%3F" class="button margin-left">
            <i class="fas fa-envelope"></i> meedenken
        </a>
    </div>
</div>