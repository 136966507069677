<div id="wat" class="anchor"></div>
<section id="section-wat compensate-header">
    <div class="container full-screen-padding text-align-center">
        <div>
            <h2>
                Verbinding tussen <span>bedrijf</span> en <span>specialist</span>
            </h2>

            <strong>Thinking Outside The Box</strong> is de handelsnaam waarmee ik mijzelf als zelfstandig software professional verbind aan bedrijven die behoefte hebben aan een goede .NET professional.
            De naam zegt het al: ik denk graag buiten de door de maatschappij opgelegde kaders, op zoek naar creatieve en duurzame oplossingen.
            <br /> <br />
            Ik word enthousiast van prettige samenwerkingen waarbij <span class="check">kwaliteit</span>, <span class="check">creativiteit</span> en <span class="check">kennisdeling</span> centraal staan.
        </div>
    </div>
</section>