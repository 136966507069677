<div id="waarden" class="anchor"></div>
<section id="section-waarden">
    <div class="container full-screen-padding text-align-center">
        <div>
            <h2>
                Wat je van mij mag verwachten 
            </h2>

            <ul class="centered">
                <li>
                    <i class="fas fa-check"></i> Betrouwbaarheid <span>stiptheid & daad bij woord voegen</span>
                </li>
                <li>
                    <i class="fas fa-check"></i> Transparantie <span>open lijnen verminderen ruis</span>
                </li>
                <li>
                    <i class="fas fa-check"></i> Kwaliteit <span>een goed product begint bij kwaliteit</span>
                </li>
                <li>
                    <i class="fas fa-check"></i> Duurzaamheid <span>een betere wereld met duurzame IT oplossingen</span>
                </li>
                <li>
                    <i class="fas fa-check"></i> Respect <span>geen oordeel; iedereen is zichzelf</span>
                </li>
            </ul>
        </div>
    </div>
</section>