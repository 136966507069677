import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core-business',
  templateUrl: './core-business.component.html',
  styleUrls: ['./core-business.component.scss']
})
export class CoreBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
