import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EyecatcherComponent } from './eyecatcher/eyecatcher.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { BlogSummaryComponent } from './blog-summary/blog-summary.component';
import { ProfileComponent } from './profile/profile.component';
import { CoreBusinessComponent } from './core-business/core-business.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { WhoComponent } from './who/who.component';
import { WhatComponent } from './what/what.component';
import { ValuesComponent } from './values/values.component';
import { NormsComponent } from './norms/norms.component';
import { SocialsComponent } from './socials/socials.component';
import { LeaveATestimonialComponent } from './leave-a-testimonial/leave-a-testimonial.component';
import { HomeComponent } from './home/home.component';
import { QuoteComponent } from './quote/quote.component';

@NgModule({
  declarations: [
    AppComponent,
    EyecatcherComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    BlogSummaryComponent,
    ProfileComponent,
    CoreBusinessComponent,
    WelcomeComponent,
    CallToActionComponent,
    WhoComponent,
    WhatComponent,
    ValuesComponent,
    NormsComponent,
    SocialsComponent,
    LeaveATestimonialComponent,
    HomeComponent,
    QuoteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
