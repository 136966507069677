<div class="container">
    <div class="profile">
        <div class="half-width">
            <div class="profile-picture"></div>
        </div>
        <div class="half-width full-height">
            <div class="padding-top">
                <h2>Hallo! Ik ben <span>Peter</span>, freelance <span>software engineer</span> met een specialisatie in <span>.NET ontwikkeling</span>.</h2>
                <ul>
                    <li>
                        <i class="fas fa-check"></i> 5+ jaar werkervaring <span>kennis van zaken</span>
                    </li>
                    <li>
                        <i class="fas fa-check"></i> Houdt van uitdagingen <span>kom maar op!</span>
                    </li>
                    <li>
                        <i class="fas fa-check"></i> Leert graag <span>zowel zelf als aan anderen</span>
                    </li>
                </ul>
                <app-socials></app-socials>
            </div>
        </div>

        <div class="clearfix"></div>
    </div>
</div>