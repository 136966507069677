<div id="normen-en-waarden" class="anchor"></div>
<div id="normen" class="anchor"></div>
<section id="section-normen compensate-header">
    <div class="container full-screen-padding text-align-center">
        <div>
            <h2>Waar ik voor ga:</h2>
            
            <div class="carrousel">
                <div class="card one-fourth-width">
                    <div class="custom-icon">
                        <img src="../../assets/images/communication.svg">
                    </div>
                    <p class="title">Transparante communicatie</p>
                    Transparantie in communicatie is de motor voor betrokkenheid en meedenkendheid van de mensen met wie je samenwerkt.
                </div>
                <div class="card one-fourth-width">
                    <div class="custom-icon">
                        <img src="../../assets/images/quality.svg">
                    </div>
                    <p class="title">100% kwaliteit</p>
                    Ik ben een rasechte developer die blij wordt van mooie, goede code; kwaliteit staat bij mij ruimschoots bovenaan. 
                </div>
                <div class="card one-fourth-width">
                    <div class="custom-icon">
                        <img src="../../assets/images/creative-solutions.svg">
                    </div>
                    <p class="title">Creatieve oplossingen</p>
                    Geen vastgeroeste mentaliteit, maar een growth mindset en buiten kaders denken. Dat biedt creatieve, duurzame oplossingen.
                </div>
                <div class="card one-fourth-width">
                    <div class="custom-icon">
                        <img src="../../assets/images/share.svg">
                    </div>
                    <p class="title">Kennisdeling</p>
                    Ik steek zelf graag iets op, bij alles wat ik doe. Daarnaast sta ik ook graag bij een whiteboard te tekenen en uitleg te geven.
                </div>

                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</section>